import { Project } from "../types";

export const toggleWeb: Project = {
  id: 3,
  name: {
    en: "Toggle Studio Web",
    ru: "Toggle Studio Web",
  },
  similarCases: [4, 5, 6],
  type: {
    en: "Corporate",
    ru: "Рабочее",
  },
  timeline: {
    en: "Sep'23 — July'24",
    ru: "Сен'23 — Июль'24",
  },
  year: "2024",
  short_deliverables: {
    en: "UI/UX & Dev",
    ru: "UI/UX & Разработка",
  },
  deliverables: {
    en: "UI/UX, Analytics, Development",
    ru: "UI/UX, Аналитика, Разработка",
  },
  filterTags: [
    {
      name: { en: "UI/UX design", ru: "UI/UX" },
      value: "uiux",
    },
    {
      name: { en: "Art direction", ru: "Арт-дирекшн" },
      value: "artdir",
    },
    {
      name: { en: "Development", ru: "Разработка" },
      value: "dev",
    },
  ],
  role: {
    en: "UI/UX designer, Developer",
    ru: "UI/UX дизайнер, разработчик",
  },
  organisation: "Toggle Studio",
  team: [
    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/design%2FtoggleAvatar.webp?alt=media&token=e7ddab7c-1177-4c8a-95b0-397909b7d741",
      link: "https://www.behance.net/toggle_studio",
    },
  ],
  status: {
    en: "Finished",
    ru: "Завершено",
  },
  s_description: {
    en: "Long awaited website for motion design studio Toggle. #MadeInWebflow.",
    ru: "Долгожданный сайт для моушн-дизайн студии Toggle. #MadeInWebflow.",
  },
  description: {
    en: "Toggle is a motion design studio with a focus on visual and multimedia content for various industries such as fashion, sports, IT, entertainment, and cybersports. Studio doesn't stick to one type of media. Toggle's work includes commercials, music videos, visuals for events, and graphic packages for films, TV shows, and online shows. Studio works all around the world, and  is represented by Final Frontier in the US.",
    ru: "Toggle — моушн-дизайн студия, специализирующаяся на визуальном и мультимедийном контенте для различных индустрий — моды, спорта, IT, развлечений и киберспорта. Студия не ограничивается одним типом медиа-контента. Проекты Toggle — это рекламные ролики, музыкальные клипы, CG для ивентов и телешоу. Студия работает по всему миру и представлена ​​Final Frontier в США.",
  },
  vertical_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/casesCovers%2Ft_w_v_cover.webp?alt=media&token=e451c15e-7929-4416-ab0b-1b37f58567b2",
  horisontal_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/casesCovers%2Ftogglewebcover.webp?alt=media&token=17058638-3c77-4cbe-8a57-b54748fb99ac",
  cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2FpcCover.webp?alt=media&token=9dd117a6-1b46-4ec0-9bab-3bee33945077",
  cover_mob:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2FmobCover.webp?alt=media&token=3d3c59f4-9fa3-498a-9333-46e54d40d0d1",
  links: [
    {
      name: {
        en: "Toggle Studio",
        ru: "Toggle Studio",
      },
      url: "https://www.toggle.studio/",
    },
    {
      name: {
        en: "Behance",
        ru: "Behance",
      },
      url: "https://www.behance.net/gallery/207579877/Toggle-Studio-Website",
    },
  ],
  slider: [
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F1.webp?alt=media&token=b2c4534e-744e-4a09-8c21-4d238f652c1d",
          description: {
            en: "Adaptive design for all types of devices",
            ru: "Адаптивный дизайн для всех устройств",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F3.gif?alt=media&token=c917c420-4835-4306-9778-2eb7ce35177b",
          description: {
            en: "Quick scroll to contacts",
            ru: "Контакты всегда под рукой",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F2.webp?alt=media&token=bc7f0249-59d1-47d8-8508-e680d86e54a9",
          description: {
            en: "Filtering and sorting for quick project search",
            ru: "Фильтрация и сортировка для быстрого поиска проектов",
          },
        },
      ],
    },
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F5.gif?alt=media&token=d10b3173-0b9c-4988-bfa7-f6c8f0a036d1",
          description: {
            en: "Basic principles of website design",
            ru: "Базовые принципы дизайна сайта",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F6.webp?alt=media&token=6cbf8f4c-4a1b-48e5-9f02-9583d7966d1b",
          description: {
            en: "Website's typography",
            ru: "Типографика",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F7.webp?alt=media&token=e302e11a-ffbf-4994-874c-f3dcf28e6bdf",
          description: {
            en: "Core color palette for website",
            ru: "Основная цветовая палитра сайта",
          },
        },
      ],
    },
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F11.gif?alt=media&token=68344484-2348-4302-9289-ecb22ea722b1",
          description: {
            en: "Anchor menu on long pages",
            ru: "Якорное меню для длинных страниц",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F12.gif?alt=media&token=5d07b4fe-fa51-45b1-8044-f9e14f32dd89",
          description: {
            en: "Fixed accessible menu",
            ru: "Фиксированное меню",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F13.gif?alt=media&token=58816572-d389-40b8-86d7-a677af8bbc50",
          description: {
            en: "Meet Toggle team",
            ru: "Шоукейс команды",
          },
        },
      ],
    },
  ],
  link_cards: [
    {
      image_src:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F14.webp?alt=media&token=3318c40c-e960-41d5-a0a0-fad71c2eb8b0",
      header: [{ en: "Projects", ru: "Проекты" }],
      text: [
        {
          en: "Dive into detailed case studies showcasing every stage of our work, all powered by CMS and custom scripts.",
          ru: "Изучите примеры кейсов на сайте Toggle, реализованные с помощью CMS и кастомных скриптов.",
        },
      ],
      link_text: [{ en: "Go to Toggle", ru: "На сайт" }],
      url: "https://www.toggle.studio/projects",
    },
  ],
  images: [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F4.gif?alt=media&token=4f0f4cd0-783c-4b1d-bb3f-c50dbe8825e0",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F8.gif?alt=media&token=fa421841-3b43-4c97-ad88-567a21c33717",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F9.gif?alt=media&token=ea01139a-18d9-4427-8e01-cccede9ceb93",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/toggleWeb%2F10.webp?alt=media&token=f27097ed-5965-41ab-aeca-9b879d65ce16",
    },
  ],
  texts: [
    {
      header: [
        {
          en: "Made in Webflow. \nPowered by us\n♥",
          ru: "Made in Webflow. \nДополнено нами\n♥",
        },
      ],
      text: [
        {
          en: "We chose Webflow for its powerful CMS, which makes it easy to manage content. It also allows non-developers to quickly update the site’s structure and design. All the necessary additional functionality was implemented through custom scripts — each one carefully optimized. The site runs so smoothly it could even fly on a Nokia 3310.",
          ru: "Мы выбрали Webflow из-за его мощной CMS, которая позволяет легко управлять контентом.  Она также позволяет пользователям без опыта в разработке быстро обновлять структуру и дизайн сайта. Все дополнительные функции были реализованы с помощью кастомных скриптов, каждый из которых был тщательно оптимизирован. Сайт работает так плавно, что мог бы летать даже на Nokia 3310.",
        },
      ],
    },
    {
      header: [{ en: "About design", ru: "Про дизайн" }],
      text: [
        {
          en: "The website design combines clarity and simplicity with a touch of punk to reflect Toggle's identity. The minimalist b&w color scheme is simple and clear, while a dynamic gradient highlights the brand’s fluid and adaptable nature. PP Neue Montreal, a neo-grotesque font, was chosen for its neutral, yet stylish appearance. It aligns with our principles and is easy to read.",
          ru: "Дизайн сайта сочетает в себе ясность и простоту. Толика панка отражает идентичность Toggle. Минималистская черно-белая цветовая палитра проста и понятна, а динамичный градиент подчёркивает гибкую и адаптивную природу бренда. Мы выбрали шрифт PP Neue Montreal. Этот нейтральный, стильный неогротеск соответствует нашим дизайн-принципам и легко считывается в наборном тексте.",
        },
      ],
      links: [
        {
          text: [{ en: "PP Neue Montreal", ru: "PP Neue Montreal" }],
          url: "https://pangrampangram.com/products/neue-montreal",
        },
      ],
    },
    {
      header: [
        { en: "About additional features", ru: "Про дополнительные фишки" },
      ],
      text: [
        {
          en: "We focused on making the site as accessible and user-friendly as possible. With intuitive navigation and features like quick-scroll, we ensured the site is easy to use and convenient for users. We also added hidden easter eggs throughout the site to make the experience more engaging and enjoyable, emphasizing that fun is just as important as functionality. Overall, our goal was to create a seamless, accessible, and fun experience for every user.",
          ru: "Мы сосредоточились на том, чтобы сделать сайт как можно более простым в использовании. Интуитивно понятная навигация и функции, вроде якорного меню, обеспечивают максимальное удобство для юзера. Мы также добавили скрытые фичи, чтобы сделать пользовательский опыт более увлекательным. В Toggle уверены, что удерживать интерес пользователя не менее важно, чем обеспечивать функциональность продукта.",
        },
      ],
    },
    {
      header: [
        {
          en: "Outstanding \ntraffic \n☻",
          ru: "Выдающийся \nтрафик \n☻",
        },
      ],
      text: [
        {
          en: "In just two weeks, by the time I left, the studio's website achieved remarkable success by attracting 2,667 unique visitors. This influx of traffic demonstrates the effectiveness of the design and marketing strategies employed, highlighting its ability to capture the attention of a broad online audience.",
          ru: "Всего за две недели, к моменту моего ухода из студии, сайт привлёк 2667 уникальных посетителей. Этот приток трафика показывает, что наши решения в дизайне и выбранные маркетинговые стратегии сработали. Сайт помог привлечь внимание широкой аудитории к бренду.",
        },
      ],
    },
    {
      header: [
        { en: "High conversion rates \n✦", ru: "Высокие конверсии \n✦" },
      ],
      text: [
        {
          en: "Site also achieved high conversion rates on key goals, including a 3.72% CTR rate for social media links and a 1.86% CTR for email links. These metrics contributed to a significant increase in project inquiries, underscoring the website's role in driving meaningful engagement and business growth.",
          ru: "Сайт также продемонстрировал высокие показатели конверсии по ключевым целям, включая 3,72% CTR на переходы в социальные сети и 1,86% CTR на переходы в электронную почту. Все это способствовало значительному росту количества запросов по проектам, подчёркивая роль сайта в росте бизнеса.",
        },
      ],
    },
  ],
  layout: [
    {
      component: "EmblaCarousel",
      sliderIndex: 0,
      references: "about",
    },
    {
      component: "A_InfoCard",
      textIndex: [0],
      references: "development",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [0],
      references: "cms",
    },
    {
      component: "M_LinkCardsGrid",
      linkCardIndex: [0],
      references: ["example"],
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 1,
      references: "design",
    },
    {
      component: "A_InfoBlock",
      textIndex: 1,
      $body: true,
      references: "aboutdesign",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [1, 2, 3],
      references: "design2",
    },
    {
      component: "A_InfoBlock",
      textIndex: 2,
      $body: true,
      references: "aboutfeatures",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 2,
      references: "features",
    },
    {
      component: "A_InfoCard",
      textIndex: [3, 4],
      references: "results",
    },
  ],
  menuItems: [
    {
      header: [
        {
          title: {
            en: "—\u2002About Toggle Web",
            ru: "—\u2002О проекте",
          },
          reference: "about",
        },
      ],
      subItems: [
        {
          title: {
            en: "Development",
            ru: "Разработка",
          },
          reference: "development",
        },
        {
          title: {
            en: "Content Management System",
            ru: "Система управления контентом",
          },
          reference: "cms",
        },
        {
          title: {
            en: "CMS usage",
            ru: "Использование CMS",
          },
          reference: "example",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002About design",
            ru: "—\u2002Про дизайн",
          },
          reference: "design",
        },
      ],
      subItems: [
        {
          title: {
            en: "Design principles and usage",
            ru: "Принципы дизайна и их применение",
          },
          reference: "aboutdesign",
        },
        {
          title: {
            en: "Grids, icons, favicon",
            ru: "Сетки, иконки, фавиконка",
          },
          reference: "design2",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Additional info",
            ru: "—\u2002Дополнительная информация",
          },
          reference: "aboutfeatures",
        },
      ],
      subItems: [
        {
          title: {
            en: "Some features",
            ru: "Некоторые фишки",
          },
          reference: "features",
        },
        {
          title: {
            en: "Results",
            ru: "Результаты",
          },
          reference: "results",
        },
      ],
    },
  ],
};
